import * as selectOptions from "@/helpers/selectOptions";
import { useFormSection, useField } from "@/components/FormBuilder/Helpers";

export const company30_Condo = [
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Is the dwelling a secondary or seasonal residence?",
        key: "dwellingType",
        options: selectOptions.yesNoOptions,
        required: true
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: `Is the risk located 2 miles or more from "Major Bodies of Salt Water" except in Aransas & San Patricio counties where a 1/2 mile or for Nueces County a distance of 1 mile is acceptable?`,
        key: "nearMajorSaltWaterBodies",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "No") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ])
  // ...useFormSection([
  //   useField(
  //     {
  //       type: "radio_button_group",
  //       preamble: `Is the dwelling a mobile or modular home?`,
  //       key: "isMobile",
  //       options: selectOptions.yesNoOptions,
  //       required: true,
  //       validations: [
  //         {
  //           method: "custom",
  //           error: "Risk is prohibited",
  //           customValidator: (value: any) => {
  //             if (value && value == "Yes") {
  //               return false;
  //             } else {
  //               return true;
  //             }
  //           }
  //         }
  //       ]
  //     },
  //     ["mb-8"]
  //   )
  // ])
];
