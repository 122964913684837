import * as selectOptions from "@/helpers/selectOptions";
import { useFormSection, useField } from "@/components/FormBuilder/Helpers";

export function companyBase(
  underwriterApprovalCode: string | undefined,
  companyNumber: string | number,
  policyType: string
) {
  return [
    ...useFormSection([
      useField(
        {
          type: "radio_button_group",
          emitRadioButtonChangeEvent: false,
          preamble:
            "Does the risk have any existing damage including flood damages which have not been properly repaired?",
          key: "damagingFloods",
          options: selectOptions.yesNoOptions,
          required: true,
          validations: [
            {
              method: "custom",
              error: "Risk is prohibited",
              customValidator: (value: any) => {
                if (value && value == "Yes" && !underwriterApprovalCode) {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        },
        ["mb-8"]
      )
    ]),
    ...useFormSection([
      useField(
        {
          type: "radio_button_group",
          emitRadioButtonChangeEvent: false,
          preamble:
            "Is there any daycare, farming other business activities or rented rooms on the premises?",
          key: "businessOnPremises",
          options: selectOptions.yesNoOptions,
          required: true,
          validations: [
            {
              method: "custom",
              error: "Risk is prohibited",
              customValidator: (value: any) => {
                if (value && value == "Yes") {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        },
        ["mb-8"]
      )
    ]),
    ...useFormSection([
      useField(
        {
          type: "radio_button_group",
          emitRadioButtonChangeEvent: false,
          preamble:
            "Is any structure on the premises being constructed, reconstructed, renovated or remodeled?",
          key: "structureUnderConstruction",
          options: selectOptions.yesNoOptions,
          required: true,
          validations: [
            {
              method: "custom",
              error: "Call Underwriter For Approval.",
              customValidator: (value: any) => {
                if (
                  value &&
                  value == "Yes" &&
                  !underwriterApprovalCode &&
                  !(companyNumber == 26 && policyType == "T")
                ) {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        },
        ["mb-8"]
      )
    ]),
    ...useFormSection([
      useField(
        {
          type: "radio_button_group",
          emitRadioButtonChangeEvent: false,
          preamble: "Is there any unusual/atypical liability exposure?",
          key: "unusualLiabilityExposure",
          options: selectOptions.yesNoOptions,
          required: true,
          validations: [
            {
              method: "custom",
              error: "Risk is prohibited",
              customValidator: (value: any) => {
                if (value && value == "Yes") {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        },
        ["mb-8"]
      )
    ]),
    ...useFormSection([
      useField(
        {
          type: "radio_button_group",
          emitRadioButtonChangeEvent: false,
          preamble:
            "Is the dwelling or any other structure located wholly or partially over water?",
          key: "overWater",
          options: selectOptions.yesNoOptions,
          required: true,
          validations: [
            {
              method: "custom",
              error: "Submit for approval",
              customValidator: (value: any) => {
                if (value && value == "Yes" && !underwriterApprovalCode) {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        },
        ["mb-8"]
      )
    ]),
    ...useFormSection([
      useField(
        {
          type: "radio_button_group",
          emitRadioButtonChangeEvent: false,
          preamble:
            "Is the current roof an overlay of the previous roof or are any portions of the roof flat over living areas?",
          key: "overlayOverLivingAreas",
          options: selectOptions.yesNoOptions,
          required: true,
          validations: [
            {
              method: "custom",
              error: "Risk is prohibited",
              customValidator: (value: any) => {
                if (value && value == "Yes") {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        },
        ["mb-8"]
      )
    ]),
    ...useFormSection([
      useField(
        {
          type: "radio_button_group",
          emitRadioButtonChangeEvent: false,
          preamble:
            "Has the applicant had a policy that has been cancelled, non-renewed, or rescinded on the basis of material misrepresentation in the past 3 years?",
          key: "hasRescindedPolicy",
          options: selectOptions.yesNoOptions,
          required: true,
          validations: [
            {
              method: "custom",
              error: "Risk is prohibited",
              customValidator: (value: any) => {
                if (value && value == "Yes") {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        },
        ["mb-8"]
      )
    ]),
    ...useFormSection([
      useField(
        {
          type: "radio_button_group",
          emitRadioButtonChangeEvent: false,
          preamble: "Is the house vacant?",
          key: "houseIsVacant",
          options: selectOptions.yesNoOptions,
          required: true
        },
        ["mb-8"]
      )
    ]),
    ...useFormSection([
      useField(
        {
          type: "radio_button_group",
          emitRadioButtonChangeEvent: false,
          preamble:
            "Has the applicant(s) ever been convicted or currently under investigation for arson, fraud, or other insurance related offenses?",
          key: "isConvicted",
          options: selectOptions.yesNoOptions,
          required: true,
          validations: [
            {
              method: "custom",
              error: "Risk is prohibited",
              customValidator: (value: any) => {
                if (value && value == "Yes") {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        },
        ["mb-8"]
      )
    ]),
    ...useFormSection([
      useField(
        {
          type: "radio_button_group",
          emitRadioButtonChangeEvent: false,
          preamble:
            "Is the dwelling on a barrier island or on land completely surrounded by water?",
          key: "surroundedByWater",
          options: selectOptions.yesNoOptions,
          required: true,
          validations: [
            {
              method: "custom",
              error: "Risk is prohibited",
              customValidator: (value: any) => {
                if (value && value == "Yes") {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        },
        ["mb-8"]
      )
    ])
  ];
}
