import * as selectOptions from "@/helpers/selectOptions";
import { useFormSection, useField } from "@/components/FormBuilder/Helpers";

export const company26_Base = [
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Is the risk isolated, not visible to neighbors, inaccessible by emergency vehicles or situated on more than a total of 20 acres including adjoining property?",
        key: "riskIsIsolated",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection(
    [
      useField(
        {
          type: "radio_button_group",
          emitRadioButtonChangeEvent: false,
          preamble:
            "Are there any dogs from the restricted list, exotic pets or animals that have a bite history on the premises?",
          key: "dogsOrExoticBreeds",
          options: selectOptions.yesNoOptions,
          required: true,
          showPopover: true,
          popoverContentTitle: "Restricted List",
          popoverContent:
            "Akita, Chow, Doberman, Mastiff, Pit Bull, Rottweiler, German Shepard, Wolf or Wolf hybrid or any mix thereof",
          popoverText: "View List",
          validations: [
            {
              method: "custom",
              error: "Risk is prohibited",
              customValidator: (value: any) => {
                if (value && value == "Yes") {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        },
        ["mb-8"]
      )
    ],
    {
      title: "",
      description: ``,
      rightSlot: {
        key: "mailingAddress",
        innerText: "Show List",
        classList: [
          "cursor-pointer",
          "text-xs",
          "text-atlas-blue",
          "hover:font-medium",
          "flex",
          "flex-row",
          "tems-end"
        ]
      }
    }
  ),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Does the applicant have a pool, hot tub, or similar structure that does not have a permanently installed locking 4 foot high fence and/or do they have an indoor pool?",
        key: "unfencedPool",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Is the dwelling of a unique design which could include but not limited to mobile/manufactured homes, homes with historical plaques, do it yourself, underground, log, dome, earth homes, greenhouses, shell, Morgan, portable, converted commercial, and iron clad buildings?",
        key: "uniqueDesign",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Does the dwelling have more than 2 solar panels?",
        key: "dwellingHasMoreThan2SolarPanels",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Does the risk have a basement with a sump pump?",
        key: "hasBasementWithSumpPump",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ])
];
