import * as selectOptions from "@/helpers/selectOptions";
import { useFormSection, useField } from "@/components/FormBuilder/Helpers";

export const company29_Condo = [
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Is the dwelling a secondary or seasonal residence?",
        key: "dwellingType",
        options: selectOptions.yesNoOptions,
        required: true
      },
      ["mb-8"]
    )
  ])
];
