import * as selectOptions from "@/helpers/selectOptions";
import { useFormSection, useField } from "@/components/FormBuilder/Helpers";

export const company28_Dwelling = [
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Does Atlas have the primary dwelling with HO-225?",
        key: "primaryDwelling",
        options: selectOptions.yesNoOptions,
        required: true
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Is the age of roof greater than 15 years?",
        key: "ageOfRoof",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Call the Underwriter",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: `Is the risk located 2 miles or more from "Major Bodies of Salt Water" except in Aransas &amp; San Patricio counties where a 1/2 mile or for Nueces County a distance of 1 mile is acceptable?`,
        key: "nearMajorSaltWaterBodies",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "No") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Does the dwelling have Aluminum wiring, Knob & Tube wiring, or use an electrical system lower than 100 amps?",
        key: "aluminumWiring",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Does the dwelling have a thermostatically controlled central heating system or a professionally installed Dearborn heater or wall unit?",
        key: "hasHeatingSystem",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "No") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ])
];
