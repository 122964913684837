import { company26_Base } from "./atlas/company26/company26_Base";
import { company26_Condo } from "./atlas/company26/company26_Condo";
import { company26_Dwelling } from "./atlas/company26/company26_Dwelling";
import { company26_Homeowner } from "./atlas/company26/company26_Homeowner";

import { company27_Base } from "./atlas/company27/company27_Base";
import { company27_Condo } from "./atlas/company27/company27_Condo";
import { company27_Dwelling } from "./atlas/company27/company27_Dwelling";
import { company27_Homeowner } from "./atlas/company27/company27_Homeowner";

import { company28_Base } from "./atlas/company28/company28_Base";
import { company28_Condo } from "./atlas/company28/company28_Condo";
import { company28_Dwelling } from "./atlas/company28/company28_Dwelling";
import { company28_Homeowner } from "./atlas/company28/company28_Homeowner";

import { company29_Base } from "./atlas/company29/company29_Base";
import { company29_Condo } from "./atlas/company29/company29_Condo";
import { company29_Dwelling } from "./atlas/company29/company29_Dwelling";
import { company29_Homeowner } from "./atlas/company29/company29_Homeowner";

import { company30_Base } from "./atlas/company30/company30_Base";
import { company30_Condo } from "./atlas/company30/company30_Condo";
import { company30_Dwelling } from "./atlas/company30/company30_Dwelling";
import { company30_Homeowner } from "./atlas/company30/company30_Homeowner";
import { companyBase } from "./atlas/companyBase";

import { sea_Coast_Base } from "./colonial/seaCoast/seaCoast_Base";
import { sea_Coast_Dwelling } from "./colonial/seaCoast/seaCoast_Dwelling";
import { sea_Coast_Homeowner } from "./colonial/seaCoast/seaCoast_Homeowner";

import { state_Wide_Base } from "./colonial/stateWide/stateWide_Base";
import { state_Wide_Dwelling } from "./colonial/stateWide/stateWide_Dwelling";
import { state_Wide_Homeowner } from "./colonial/stateWide/stateWide_Homeowner";
import { companyColonialBase } from "./colonial/companyColonialBase";
import { FormBlock } from "@/components/FormBuilder/types";
import { isColonial } from "@/forms/utils";
export default function getQuestions(
  company: number,
  policyType: string,
  isStateWide?: boolean,
  underwriterApprovalCode?: string
): FormBlock[] {
  let response: FormBlock[] = [];
  if (!company) return response;
  if (isColonial([company])) {
    switch (true) {
      case isStateWide && policyType == "T":
        response = [...state_Wide_Base, ...companyColonialBase];
        break;
      case isStateWide && policyType == "D":
        response = [
          ...state_Wide_Base,
          ...state_Wide_Dwelling,
          ...companyColonialBase
        ];
        break;
      case isStateWide && policyType == "H":
        response = [
          ...state_Wide_Base,
          ...state_Wide_Homeowner,
          ...companyColonialBase
        ];
        break;
      case !isStateWide && policyType == "T":
        response = [...sea_Coast_Base, ...companyColonialBase];
        break;
      case !isStateWide && policyType == "D":
        response = [
          ...sea_Coast_Dwelling,
          ...sea_Coast_Base,
          ...companyColonialBase
        ];
        break;
      case !isStateWide && policyType == "H":
        response = [
          ...sea_Coast_Homeowner,
          ...sea_Coast_Base,
          ...companyColonialBase
        ];
        break;
    }
  } else {
    switch (company) {
      case 26:
        switch (policyType) {
          case "T":
            response = [
              ...company26_Base,
              ...company26_Condo,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
          case "D":
            response = [
              ...company26_Base,
              ...company26_Dwelling,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
          case "H":
            response = [
              ...company26_Base,
              ...company26_Homeowner,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
        }
        break;
      case 27:
        switch (policyType) {
          case "T":
            response = [
              ...company27_Base,
              ...company27_Condo,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
          case "D":
            response = [
              ...company27_Base,
              ...company27_Dwelling,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
          case "H":
            response = [
              ...company27_Base,
              ...company27_Homeowner,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
        }
        break;
      case 28:
        switch (policyType) {
          case "T":
            response = [
              ...company28_Base,
              ...company28_Condo,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
          case "D":
            response = [
              ...company28_Base,
              ...company28_Dwelling,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
          case "H":
            response = [
              ...company28_Base,
              ...company28_Homeowner,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
        }
        break;
      case 29:
        switch (policyType) {
          case "T":
            response = [
              ...company29_Base,
              ...company29_Condo,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
          case "D":
            response = [
              ...company29_Base,
              ...company29_Dwelling,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
          case "H":
            response = [
              ...company29_Base,
              ...company29_Homeowner,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
        }
        break;
      case 30:
        switch (policyType) {
          case "T":
            response = [
              ...company30_Base,
              ...company30_Condo,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
          case "D":
            response = [
              ...company30_Base,
              ...company30_Dwelling,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
          case "H":
            response = [
              ...company30_Base,
              ...company30_Homeowner,
              ...companyBase(underwriterApprovalCode, company, policyType)
            ];
            break;
        }
        break;
    }
  }

  return response;
}
