import * as selectOptions from "@/helpers/selectOptions";
import { useFormSection, useField } from "@/components/FormBuilder/Helpers";
import store from "@/store";

export const companyColonialBase = [
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Is the dwelling a secondary or seasonal residence?",
        key: "dwellingType",
        options: selectOptions.yesNoOptions,
        required: true
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Does the risk have any existing damage including flood damages which have not been properly repaired?",
        key: "damagingFloods",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Is there any unusual/atypical liability exposure?",
        key: "unusualLiabilityExposure",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Is the dwelling or any other structure located wholly or partially over water?",
        key: "overWater",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Not Covered by Policy.",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Is the current roof an overlay of the previous roof or are any portions of the roof flat over living areas?",
        key: "overlayOverLivingAreas",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Does the applicant have a pool, hot tub, or similar structure that does not have a permanently installed locking 5 foot high fence and/or do they have an indoor pool?",
        key: "unfencedPool",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error:
              store.state.quote.editing &&
              store.state.quote.editing.policyType === "D"
                ? "Not eligible for Personal Liability policy"
                : "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Is the dwelling of a unique design which could include but not limited to mobile/manufactured homes, homes with historical plaques, do it yourself, underground, log, dome, earth homes, greenhouses, shell, Morgan, portable, converted commercial, and iron clad buildings?",
        key: "uniqueDesign",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Has the applicant had a policy that has been cancelled, non-renewed, or rescinded on the basis of material misrepresentation in the past 3 years?",
        key: "hasRescindedPolicy",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Is the house vacant?",
        key: "houseIsVacant",
        options: selectOptions.yesNoOptions,
        required: true
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Is the risk isolated, not visible to neighbors, inaccessible by emergency vehicles or situated on more than a total of 20 acres including adjoining property?",
        key: "riskIsIsolated",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Does the dwelling have more than 2 solar panels?",
        key: "dwellingHasMoreThan2SolarPanels",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Has the applicant(s) ever been convicted or currently under investigation for arson, fraud, or other insurance related offenses?",
        key: "isConvicted",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ])
];
