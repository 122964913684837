import * as selectOptions from "@/helpers/selectOptions";
import { useFormSection, useField } from "@/components/FormBuilder/Helpers";
import store from "@/store";

export const sea_Coast_Dwelling = [
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Does Colonial have the primary dwelling with HO-225?",
        key: "primaryDwelling",
        options: selectOptions.yesNoOptions,
        required: true
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Is there any daycare, farming other business activities or rental rooms on the premises?",
        key: "businessOnPremises",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Is any structure on the premises being constructed, reconstructed, renovated or remodeled?",
        key: "structureUnderConstruction",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Submit For Approval",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),

  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: `Is the age of roof greater than ${
          store.state.quote.editing &&
          store.state.quote.editing.roofMaterial == "6"
            ? "10"
            : "15"
        } years?`,
        key: "ageOfRoof",
        options: selectOptions.yesNoOptions,
        required: true
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: `Are there any unusual, exotic, farm or saddle animals (hoofed animals, livestock, swine, fowl), naturally venomous animals, reptiles, non-domesticated cats, primates, birds of any kind or the following breeds of dogs (Akita, Chow, Doberman, Mastiff, Pit Bull, Rottweiler, German Shepherd, Wolf/Wolf Hybrid or any mix thereof) or dogs with a bite history?`,
        key: "dogsOrExoticBreeds",
        options: selectOptions.yesNoOptions,
        required: true
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble: "Does the risk have a basement with a sump pump?",
        key: "hasBasementWithSumpPump",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Does the dwelling have Aluminum wiring, Knob & Tube wiring, or use an electrical system lower than 60 amps?",
        key: "aluminumWiring",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "Yes") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ]),
  ...useFormSection([
    useField(
      {
        type: "radio_button_group",
        emitRadioButtonChangeEvent: false,
        preamble:
          "Does the dwelling have a thermostatically controlled central heating system or a professionally installed Dearborn heater or wall unit?",
        key: "hasHeatingSystem",
        options: selectOptions.yesNoOptions,
        required: true,
        validations: [
          {
            method: "custom",
            error: "Risk is prohibited",
            customValidator: (value: any) => {
              if (value && value == "No") {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      ["mb-8"]
    )
  ])
];
